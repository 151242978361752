import React from 'react'

import { PageLayout } from '../components/layout/pageLayout/pageLayout'
import { Relevant } from '../components/relevant/relevant'
import { Emoji } from '../components/emoji/emoji'
import { LinkActionButton } from '../components/buttons/linkActionButton/linkActionButton'

export default () => (
  <PageLayout>
    <div
      style={{
        width: `100%`,
        height: `100%`,
        display: `flex`,
        flexDirection: `column`,
        textAlign: `center`
      }}
    >
      <h1>
        <Relevant>404</Relevant>
      </h1>
      <p>
        This page doesn't exist <Emoji label="sad face" emoji="😔"></Emoji>
      </p>
      <LinkActionButton to={'/'}>Go back to the home page</LinkActionButton>
    </div>
  </PageLayout>
)
